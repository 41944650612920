import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Tabs from "../constants/Tabs";
import UserContext from "../contexts/UserContext";





export default function Navbar () {

    const {userData} = React.useContext(UserContext)

    return(
        <>
            <div className="
            navbar
            flex flex-shrink-0
            flex-col h-full w-[15%]
            justify-evenly items-center
            ">
                <NavLink className={({isActive}) => (isActive ? 'activeNavlink' : 'navlink')} to={''}>Home</NavLink>
                {Tabs.map(e => (
                    userData["cognito:groups"].split(',').includes(e.permissions)
                    ? <NavLink className={({isActive}) => (isActive ? 'activeNavlink' : 'navlink')} to={e.route} key={e.route}>{e.display}</NavLink>
                    : <></>
                ))}
            </div>

            <div className="
            greenback text
            h-full w-full
            ">
                <Outlet />
            </div>
        </>
    )
}

