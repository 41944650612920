import React from "react";
import UserContext from "../contexts/UserContext";
import { NavLink, useNavigate } from "react-router-dom";





export default function User () {

    const {userData, token} = React.useContext(UserContext)
    
    const navigate = useNavigate()


    const [loading, setLoading] = React.useState(true)
    const [operator, setOperator] = React.useState<any>({})
    const [editing, setEditing] = React.useState(false)

    const [editName, setEditName] = React.useState('')
    const [editQual, setEditQual] = React.useState('')

    const [warning, setWarning] = React.useState('')

    React.useEffect(()=>{
        fetch('https://operator.arkanic.com/', {
            method: 'POST',
            headers: {
                token: token
            },
            body: JSON.stringify({'function':'get', 'user-id': userData['cognito:username']})
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            res.json().then(v => {
                setOperator(v)
            })
            setLoading(false)
        }).catch(e => {
            console.error('FETCH CRASHED')
            console.error(e)
        })
    },[])

    React.useEffect(()=>{
        if (operator) {
            setEditName(operator['name'])
            setEditQual(operator['qualification'])
        }
    },[operator])


    if (loading) {return <div className="centralise text">Loading</div>}

    if (!editing) {return(
        <div className="centralise text">
            {/* <div>Image: [coming soon]</div> */}
            <div>Email: {userData['email']}</div>
            <div>Name: {operator['name']}</div>
            <div>Qualification: {operator['qualification']}</div>
            <div className="editButton">
                <button onClick={e=>setEditing(true)}>Edit Data</button>
            </div>
        </div>
    )}


    function attemptUpdate() {
        if (!editName.trim()) {
            setWarning('ERROR: Name cannot be blank')
        } else {
            fetch('https://operator.arkanic.com/', {
                method: 'POST',
                headers: {token: token},
                body: JSON.stringify({'function':'update', 'name': editName, 'qual':editQual})
            }).then(res => {
                if (!res.ok) {
                    throw {
                        error: 'Request error',
                        code: res.status,
                        response: res
                    }
                }
                res.json().then(v => navigate('/refreshuser', {replace:true}))
            }).catch(e => {
                setWarning('ERROR: Failed')
                console.error('FETCH CRASHED')
                console.error(e)
            })
        }
    }



    return(
        <div className="centralise text">
            {/* <div>Image: [coming soon]</div> */}
            <div>Email: {userData['email']}</div>
            <div className="centralrow">
                <div>Name:</div>
                <input
                    className="rounded-xl p-2"
                    placeholder="Name"
                    value={editName}
                    onChange={e=>{setEditName(e.target.value)}}
                />
            </div>
            <div className="centralrow">
                <div>Qualification:</div>
                <input
                    className="rounded-xl p-2"
                    placeholder="Qualification"
                    value={editQual}
                    onChange={e=>{setEditQual(e.target.value)}}
                />
            </div>
            <div className="centralrow">
                <div className="logoutButton">
                    <button onClick={e=>setEditing(false)}>Cancel</button>
                </div>
                <div className="errortext mx-[-10%]">{warning}</div>
                <div className="logoutButton">
                    <button onClick={e=>attemptUpdate()}>Update</button>
                </div>
            </div>
        </div>
    )
}

