import React from 'react';
import CheckToken from '../functions/CheckToken';
import { UserDataType } from '../Types';
import LoginContext from '../contexts/LoginContext';
import LoginStack from './LoginStack';
import UserContext from '../contexts/UserContext';
import MainStack from './MainStack';
import { useLocation, useNavigate } from 'react-router-dom';


export default function App() {

	const location = useLocation()
	const navigate = useNavigate()

	const [token, setToken] = React.useState('')
	const [logoutTime, setLogoutTime] = React.useState(new Date(Date.now()))
	const [userData, setUserData] = React.useState<UserDataType>({} as UserDataType)

	const [tryingLogin, setTryingLogin] = React.useState(false)
	const [attempt, setAttempt] = React.useState(false)

	React.useEffect(()=>{
		if (location.hash) {
			setToken(location.hash.slice(1).split('&')[0].split('=')[1])
			navigate('/')
		}
	},[])

	React.useEffect(()=>{

		if (token === '') {
			setLogoutTime(new Date(Date.now()))
			setUserData({} as UserDataType)
			setTryingLogin(false)
			return
		}

		setTryingLogin(true)

		CheckToken(token).then(res =>{

			setUserData(res)

			const logout = new Date(res['logout'])
			logout.setHours(logout.getHours() - 2, logout.getMinutes() - 0)
			setLogoutTime(logout)

			setTryingLogin(false)

		})
		.catch(error => {

			console.error('Login error')
			console.log(JSON.stringify(error))

			setToken('')
			setAttempt(true)
		})

	},[token])


	if (Date.now() < logoutTime.valueOf()) {
		return (
			<UserContext.Provider value={{userData, token, setToken, logoutTime}}>
				<MainStack />
			</UserContext.Provider>
		)
	}

  
	return (
		<LoginContext.Provider value={{setToken, attempt, tryingLogin}}>
			<LoginStack />
		</LoginContext.Provider>
	)
}

