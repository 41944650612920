import React from "react";
import UserContext from "../contexts/UserContext";
import TriageOne from "../components/TriageOne";





export default function Triage () {

    const {token} = React.useContext(UserContext)

    const [triageList, setTriageList] = React.useState([])
    const [scanned, setScanned] = React.useState(false)


    React.useEffect(()=>{
        fetch('https://triage.arkanic.com/', {
            method: 'POST',
            headers: {
                token: token
            },
            body: JSON.stringify({function: 'scan'})
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            res.json().then(v => {
                if ("Items" in v) {
                    setTriageList(v['Items'])
                }
            })
            setScanned(true)
        }).catch(e => {
            console.error('FETCH CRASHED')
            console.error(e)
        })
    },[])

    React.useEffect(()=> {console.log(triageList)}, [triageList])


    if (scanned) {
        if (triageList && triageList.length) {return(<div className="centralise text overflow-y-scroll">
            {triageList.map(e => {return(<TriageOne operator={e}/>)})}
        </div>)} else {return(
        <div className="centralise text">List Empty</div>
        )}
    }
    else {return(
        <div className="centralise text">Scanning</div>
    )}
}

