import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";





export default function UserRefresh () {
    
    const navigate = useNavigate()

    useEffect(()=>{
        navigate('/user', {replace:true})
    },[])
    
    return null
}

