import React from "react";
import UserContext from "../contexts/UserContext";
import { UserDataType } from "../Types";





export default function Home () {
    
    const {logoutTime, setToken, token, userData} = React.useContext(UserContext)

    function printLog() {
        console.log(userData)
        console.log(token)
    }

    function logout() {
        setToken('')
    }
    
    return(
        <>
            <div className="centralise text">
                <div>
                    Welcome<button onClick={e => printLog()}>,</button> {userData['email']}
                </div>
                <div>
                    Your login will expire at {logoutTime.toTimeString().split(' ')[0]}
                </div>
                <div className="logoutButton">
                    <button onClick={e => logout()}>
                        Logout
                    </button>
                </div>
            </div>
        </>
    )
}

