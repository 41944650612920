import React from "react";
import LoginContext from "../contexts/LoginContext";



export default function LoginStack () {

    const {attempt, tryingLogin} = React.useContext(LoginContext)

    const attemptError = (<div className='errortext text p-5'>LOGIN ERROR</div>)
    const loadingText = (<div className='errortext text p-5'>LOADING</div>)


    return(
        <div className="
            blueback
            w-full h-full flex flex-col overflow-hidden
            justify-evenly items-center
        ">
            <div className="
                text
            ">
                WELCOME TO ARKANIC DASHBOARD
            </div>
            {
                (tryingLogin && ~(attempt)) ? loadingText : <a className="
                button text
                p-5 rounded-full
                "
                // href="https://arkanic.auth.ap-south-1.amazoncognito.com/login?client_id=5rd8canp31bsjb6du6ejpalkna&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=token"
                href="https://arkanic.auth.ap-south-1.amazoncognito.com/login?client_id=5rd8canp31bsjb6du6ejpalkna&redirect_uri=https%3A%2F%2Fdashboard.arkanic.com&response_type=token"
                >
                    <button>
                        LOGIN
                    </button>
                </a>
            }
            {attempt ? attemptError : <div></div>}
        </div>
    )
}

