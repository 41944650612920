import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import Error from "../pages/Error";
import ErrorRedirect from "../pages/ErrorRedirect";
import Home from "../pages/Home";
import Tabs from "../constants/Tabs";
import UserContext from "../contexts/UserContext";
import UserRefresh from "../pages/UserRefresh";





export default function MainStack () {

    const {userData} = React.useContext(UserContext)

    return(
        
        <div className="
            w-full h-full flex flex-row overflow-hidden
        ">
            <Routes>
                <Route path='/' element={<Navbar />}>
                    <Route path="" element={<Home />} />
                    {Tabs.map(e => (
                        userData["cognito:groups"].split(',').includes(e.permissions)
                        ? <Route path={e.route} element={e.element} />
                        : <></>
                    ))}
                </Route>
                <Route path='refreshuser' element={<UserRefresh />}/>
                <Route path='error' element={<Error />}/>
                <Route path='*' element={<ErrorRedirect />}/>
            </Routes>
        </div>
    )
}

