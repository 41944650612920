import { UserDataType } from "../Types"



const CheckToken = (token : string) : Promise<UserDataType> => {
    return fetch('https://internal.arkanic.com/', {
        method: 'GET',
        headers: {
            token: token,
        }
    })
    .then(res => {
        if (!res.ok) { 
            throw {
                error: 'Request error',
                code: res.status,
                response: res
            }
        }
        return res.json().then(r=>r['claims'])
    })
}

export default CheckToken
