import React from "react";
import UserContext from "../contexts/UserContext";



export default function TriageOne ({operator}:any) {

    const {token} = React.useContext(UserContext)

    function conf(name:string) {

        const optosend = {
            'email': operator['email'],
            'user-id': operator['user-id'],
            'name': name,
            'qualification': '',
        }

        fetch('https://triage.arkanic.com/', {
            method: 'POST',
            headers: {
                token: token
            },
            body: JSON.stringify({
                function: 'confirm',
                operator: optosend
            })
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            console.log(optosend)
            console.log('Done')
            res.json().then(c=>console.log(c))
        }).catch(e => {
            console.error('FETCH FAILED')
            console.error(e)
        })
    }

    return(
        <div className="centralise flex-row min-h-[10%]">
            <div> {operator['email']} </div>
            <button onClick={e=> conf(operator['name'])}>Confirm as {operator['name']}</button>
            <button onClick={e=> conf('Arkanic Farmer')}>Confirm as Arkanic Farmer</button>
        </div>
    )
}

